import React from 'react';

export const Header = () => (
  <div className="flex items-center justify-between mb-8">
    <div>
      <h1 className="text-3xl font-bold text-gray-800 mb-2">
        Pulmonary & Sleep Lab
      </h1>
      <h2 className="text-xl text-gray-600">
        Nile Hospital Naqada
      </h2>
    </div>
    <img src="/logo.png" alt="NHN Logo" className="h-16" />
  </div>
);