import { useState, useEffect } from 'react';
import { db } from '../db';
import { MedicalRecordSettings } from '../types/settings';

const DEFAULT_SETTINGS: MedicalRecordSettings = {
  customFields: [],
  vitalSigns: {
    'Blood Pressure': { min: 90, max: 140 },
    'Heart Rate': { min: 60, max: 100 },
    'Temperature': { min: 36.5, max: 37.5 },
    'Respiratory Rate': { min: 12, max: 20 },
    'SpO2': { min: 95, max: 100 }
  },
  templates: {
    prescription: '',
    report: ''
  }
};

export function useMedicalRecordSettings() {
  const [settings, setSettings] = useState<MedicalRecordSettings>(DEFAULT_SETTINGS);

  useEffect(() => {
    const loadSettings = async () => {
      const savedSettings = await db.table('settings').get('medical-records');
      if (savedSettings) {
        setSettings(savedSettings);
      }
    };

    loadSettings();
  }, []);

  const updateSettings = async (updates: Partial<MedicalRecordSettings>) => {
    const newSettings = { ...settings, ...updates };
    await db.table('settings').put(newSettings, 'medical-records');
    setSettings(newSettings);
  };

  return { settings, updateSettings };
}