import React from 'react';

export const Footer = () => (
  <footer className="mt-auto pt-8 pb-4">
    <div className="flex justify-end items-center">
      <p className="text-sm text-gray-600">
        Created by <span className="font-medium">Khairy Gadalla</span>
      </p>
    </div>
  </footer>
);