import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getPatientById, createVisit, createPulmonaryTest, createSleepStudy } from '../db';
import { Patient } from '../types';

export default function VisitRegistration() {
  const { patientId } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('pulmonary');
  const [patient, setPatient] = useState<Patient | null>(null);
  const [formData, setFormData] = useState({
    chiefComplaint: '',
    symptoms: '',
    medicalHistory: '',
    physicalExamination: '',
    recommendations: '',
    medications: '',
    // Pulmonary fields
    fev1: '',
    fvc: '',
    fev1fvc: '',
    pef: '',
    spo2: '',
    sixMwtDistance: '',
    // Sleep study fields
    ahi: '',
    odi: '',
    remDuration: '',
    sleepEfficiency: '',
    cpapHoursUse: '',
    cpapPressure: '',
    epworthScore: '',
    isiScore: ''
  });

  useEffect(() => {
    const loadPatient = async () => {
      if (patientId) {
        const patientData = await getPatientById(Number(patientId));
        setPatient(patientData || null);
      }
    };
    loadPatient();
  }, [patientId]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!patient?.id) return;

    try {
      // Create visit record with recommendations and medications
      const visitId = await createVisit({
        patientId: patient.id,
        visitType: activeTab as 'pulmonary' | 'sleep',
        chiefComplaint: formData.chiefComplaint,
        symptoms: formData.symptoms,
        medicalHistory: formData.medicalHistory,
        physicalExamination: formData.physicalExamination,
        recommendations: formData.recommendations,
        medications: formData.medications
      });

      // Create specific test record based on visit type
      if (activeTab === 'pulmonary') {
        await createPulmonaryTest({
          visitId,
          fev1: formData.fev1 ? Number(formData.fev1) : undefined,
          fvc: formData.fvc ? Number(formData.fvc) : undefined,
          fev1fvc: formData.fev1fvc ? Number(formData.fev1fvc) : undefined,
          pef: formData.pef ? Number(formData.pef) : undefined,
          spo2: formData.spo2 ? Number(formData.spo2) : undefined,
          sixMwtDistance: formData.sixMwtDistance ? Number(formData.sixMwtDistance) : undefined
        });
      } else {
        await createSleepStudy({
          visitId,
          ahi: formData.ahi ? Number(formData.ahi) : undefined,
          odi: formData.odi ? Number(formData.odi) : undefined,
          remDuration: formData.remDuration ? Number(formData.remDuration) : undefined,
          sleepEfficiency: formData.sleepEfficiency ? Number(formData.sleepEfficiency) : undefined,
          cpapHoursUse: formData.cpapHoursUse ? Number(formData.cpapHoursUse) : undefined,
          cpapPressure: formData.cpapPressure ? Number(formData.cpapPressure) : undefined,
          epworthScore: formData.epworthScore ? Number(formData.epworthScore) : undefined,
          isiScore: formData.isiScore ? Number(formData.isiScore) : undefined
        });
      }

      alert('Visit recorded successfully!');
      navigate(`/reports/${patient.id}`);
    } catch (error) {
      console.error('Error recording visit:', error);
      alert('Error recording visit. Please try again.');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  if (!patient) {
    return <div>Loading patient data...</div>;
  }

  return (
    <div>
      <h1 className="text-2xl font-bold text-gray-800 mb-8">Record Visit</h1>

      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="mb-6">
          <h2 className="text-lg font-semibold">Patient: {patient.fullName}</h2>
          <p className="text-gray-600">Phone: {patient.phoneNumber}</p>
        </div>

        <div className="border-b border-gray-200 mb-6">
          <nav className="-mb-px flex space-x-8">
            <button
              onClick={() => setActiveTab('pulmonary')}
              className={`${
                activeTab === 'pulmonary'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
            >
              Pulmonary Clinic
            </button>
            <button
              onClick={() => setActiveTab('sleep')}
              className={`${
                activeTab === 'sleep'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
            >
              Sleep Disorders
            </button>
          </nav>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Common Fields */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Chief Complaint
              </label>
              <textarea
                name="chiefComplaint"
                value={formData.chiefComplaint}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                rows={4}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Symptoms
              </label>
              <textarea
                name="symptoms"
                value={formData.symptoms}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                rows={4}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Medical History
              </label>
              <textarea
                name="medicalHistory"
                value={formData.medicalHistory}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                rows={4}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Physical Examination
              </label>
              <textarea
                name="physicalExamination"
                value={formData.physicalExamination}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                rows={4}
              />
            </div>

            {/* New Fields for Recommendations and Medications */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Recommendations
              </label>
              <textarea
                name="recommendations"
                value={formData.recommendations}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                rows={4}
                placeholder="Enter treatment recommendations..."
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Medications
              </label>
              <textarea
                name="medications"
                value={formData.medications}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                rows={4}
                placeholder="Enter prescribed medications..."
              />
            </div>

            {/* Conditional Fields based on Visit Type */}
            {activeTab === 'pulmonary' ? (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Pulmonary Function Tests
                  </label>
                  <div className="grid grid-cols-2 gap-4">
                    <input
                      type="number"
                      name="fev1"
                      placeholder="FEV1"
                      value={formData.fev1}
                      onChange={handleChange}
                      className="p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                      step="0.01"
                    />
                    <input
                      type="number"
                      name="fvc"
                      placeholder="FVC"
                      value={formData.fvc}
                      onChange={handleChange}
                      className="p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                      step="0.01"
                    />
                    <input
                      type="number"
                      name="fev1fvc"
                      placeholder="FEV1/FVC"
                      value={formData.fev1fvc}
                      onChange={handleChange}
                      className="p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                      step="0.01"
                    />
                    <input
                      type="number"
                      name="pef"
                      placeholder="PEF"
                      value={formData.pef}
                      onChange={handleChange}
                      className="p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                      step="0.01"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Respiratory Assessments
                  </label>
                  <div className="grid grid-cols-2 gap-4">
                    <input
                      type="number"
                      name="spo2"
                      placeholder="SpO2"
                      value={formData.spo2}
                      onChange={handleChange}
                      className="p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                      step="0.1"
                    />
                    <input
                      type="number"
                      name="sixMwtDistance"
                      placeholder="6MWT Distance"
                      value={formData.sixMwtDistance}
                      onChange={handleChange}
                      className="p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Sleep Study Results
                  </label>
                  <div className="grid grid-cols-2 gap-4">
                    <input
                      type="number"
                      name="ahi"
                      placeholder="AHI"
                      value={formData.ahi}
                      onChange={handleChange}
                      className="p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                      step="0.1"
                    />
                    <input
                      type="number"
                      name="odi"
                      placeholder="ODI"
                      value={formData.odi}
                      onChange={handleChange}
                      className="p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                      step="0.1"
                    />
                    <input
                      type="number"
                      name="remDuration"
                      placeholder="REM Duration"
                      value={formData.remDuration}
                      onChange={handleChange}
                      className="p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                      step="0.1"
                    />
                    <input
                      type="number"
                      name="sleepEfficiency"
                      placeholder="Sleep Efficiency"
                      value={formData.sleepEfficiency}
                      onChange={handleChange}
                      className="p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                      step="0.1"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    CPAP & Assessments
                  </label>
                  <div className="grid grid-cols-2 gap-4">
                    <input
                      type="number"
                      name="cpapHoursUse"
                      placeholder="CPAP Hours"
                      value={formData.cpapHoursUse}
                      onChange={handleChange}
                      className="p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                      step="0.1"
                    />
                    <input
                      type="number"
                      name="cpapPressure"
                      placeholder="CPAP Pressure"
                      value={formData.cpapPressure}
                      onChange={handleChange}
                      className="p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                      step="0.1"
                    />
                    <input
                      type="number"
                      name="epworthScore"
                      placeholder="Epworth Score"
                      value={formData.epworthScore}
                      onChange={handleChange}
                      className="p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                    />
                    <input
                      type="number"
                      name="isiScore"
                      placeholder="ISI Score"
                      value={formData.isiScore}
                      onChange={handleChange}
                      className="p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="mt-6 flex justify-end">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Save Visit Record
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}