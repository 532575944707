import { db } from '../index';
import { Visit, PulmonaryTest, SleepStudy } from '../../types';

export const createVisit = async (visitData: Omit<Visit, 'id' | 'visitDate'>): Promise<number> => {
  const visit: Omit<Visit, 'id'> = {
    ...visitData,
    visitDate: new Date().toISOString()
  };
  
  return await db.visits.add(visit);
};

export const createPulmonaryTest = async (testData: Omit<PulmonaryTest, 'id'>): Promise<number> => {
  return await db.pulmonaryTests.add(testData);
};

export const createSleepStudy = async (studyData: Omit<SleepStudy, 'id'>): Promise<number> => {
  return await db.sleepStudies.add(studyData);
};

export const getPatientVisits = async (patientId: number): Promise<Visit[]> => {
  const visits = await db.visits
    .where('patientId')
    .equals(patientId)
    .reverse()
    .toArray();

  const enrichedVisits = await Promise.all(
    visits.map(async visit => {
      if (!visit.id) return visit;

      const [pulmonaryTest, sleepStudy] = await Promise.all([
        db.pulmonaryTests.where('visitId').equals(visit.id).first(),
        db.sleepStudies.where('visitId').equals(visit.id).first()
      ]);

      return {
        ...visit,
        pulmonaryTest,
        sleepStudy
      };
    })
  );

  return enrichedVisits;
};

export const getVisitById = async (id: number): Promise<Visit | undefined> => {
  return await db.visits.get(id);
};

export const updateVisit = async (id: number, data: Partial<Visit>): Promise<number> => {
  return await db.visits.update(id, data);
};

export const deleteVisit = async (id: number): Promise<void> => {
  await db.visits.delete(id);
};