import React from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import { getRecentPatients } from '../../db';
import { formatDate } from '../../utils/dates';

export const RecentPatients = () => {
  const recentPatients = useLiveQuery(() => getRecentPatients(5));

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <h2 className="text-lg font-semibold mb-4">Recent Patients</h2>
      <div className="divide-y">
        {(recentPatients || []).map((patient) => (
          <div key={patient.id} className="py-3">
            <p className="font-medium">{patient.fullName}</p>
            <p className="text-sm text-gray-500">
              {patient.phoneNumber} • {formatDate(patient.createdAt || '')}
            </p>
          </div>
        ))}
        {(!recentPatients || recentPatients.length === 0) && (
          <p className="text-center text-gray-500 py-4">No recent patients</p>
        )}
      </div>
    </div>
  );
};