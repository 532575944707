export const DB_NAME = 'EmrDatabase';
export const DB_VERSION = 2;

export const DB_SCHEMA = {
  patients: '++id, fullName, phoneNumber, &createdAt, *tags',
  visits: '++id, patientId, visitDate, visitType',
  pulmonaryTests: '++id, visitId',
  sleepStudies: '++id, visitId',
  settings: 'key, value',
  users: '++id, email, role',
  appointments: '++id, patientId, doctorId, date, time, status, createdAt'
};