import { db } from '../index';
import { Appointment, AppointmentStatus } from '../../types/appointment';
import { format, parseISO, isWithinInterval, startOfDay, endOfDay } from 'date-fns';

export const createAppointment = async (appointmentData: Omit<Appointment, 'id' | 'createdAt' | 'updatedAt'>): Promise<number> => {
  const timestamp = new Date().toISOString();
  const appointment: Omit<Appointment, 'id'> = {
    ...appointmentData,
    createdAt: timestamp,
    updatedAt: timestamp
  };
  
  return await db.appointments.add(appointment);
};

export const getAppointmentById = async (id: number): Promise<Appointment | undefined> => {
  return await db.appointments.get(id);
};

export const getDayAppointments = async (date: string): Promise<Appointment[]> => {
  const dayStart = startOfDay(parseISO(date));
  const dayEnd = endOfDay(parseISO(date));

  return await db.appointments
    .where('date')
    .between(dayStart.toISOString(), dayEnd.toISOString())
    .toArray();
};

export const getPatientAppointments = async (patientId: number): Promise<Appointment[]> => {
  return await db.appointments
    .where('patientId')
    .equals(patientId)
    .reverse()
    .toArray();
};

export const updateAppointmentStatus = async (
  id: number, 
  status: AppointmentStatus
): Promise<number> => {
  return await db.appointments.update(id, { 
    status,
    updatedAt: new Date().toISOString()
  });
};

export const deleteAppointment = async (id: number): Promise<void> => {
  await db.appointments.delete(id);
};

export const getAvailableTimeSlots = async (
  date: string,
  doctorId: number,
  duration: number = 30
): Promise<string[]> => {
  const workingHours = await db.settings.get('clinic');
  const dayOfWeek = format(parseISO(date), 'EEEE');
  const { start, end } = workingHours?.workingHours?.[dayOfWeek] || { start: '09:00', end: '17:00' };
  
  const existingAppointments = await getDayAppointments(date);
  const bookedTimes = new Set(existingAppointments.map(apt => apt.time));
  
  const slots: string[] = [];
  let currentTime = parseISO(`${date}T${start}`);
  const endTime = parseISO(`${date}T${end}`);
  
  while (currentTime < endTime) {
    const timeString = format(currentTime, 'HH:mm');
    if (!bookedTimes.has(timeString)) {
      slots.push(timeString);
    }
    currentTime = new Date(currentTime.getTime() + duration * 60000);
  }
  
  return slots;
};