import { useState, useEffect } from 'react';
import { db } from '../db';
import { SystemSettings } from '../types/settings';

const DEFAULT_SETTINGS: SystemSettings = {
  language: 'en',
  dateFormat: 'MM/DD/YYYY',
  theme: 'light',
  primaryColor: '#2563eb'
};

export function useSystemSettings() {
  const [settings, setSettings] = useState<SystemSettings>(DEFAULT_SETTINGS);

  useEffect(() => {
    const loadSettings = async () => {
      const savedSettings = await db.table('settings').get('system');
      if (savedSettings) {
        setSettings(savedSettings);
      }
    };

    loadSettings();
  }, []);

  const updateSettings = async (updates: Partial<SystemSettings>) => {
    const newSettings = { ...settings, ...updates };
    await db.table('settings').put(newSettings, 'system');
    setSettings(newSettings);
  };

  return { settings, updateSettings };
}