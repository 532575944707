import { useState, useEffect } from 'react';
import { db } from '../db';
import { User } from '../types/settings';

export function useUsers() {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    const loadUsers = async () => {
      const loadedUsers = await db.table('users').toArray();
      setUsers(loadedUsers);
    };

    loadUsers();
  }, []);

  const addUser = async (user: Omit<User, 'id'>) => {
    const id = await db.table('users').add({
      ...user,
      lastActive: new Date().toISOString(),
      status: 'active'
    });
    setUsers([...users, { ...user, id } as User]);
    return id;
  };

  const updateUser = async (id: number, updates: Partial<User>) => {
    await db.table('users').update(id, updates);
    setUsers(users.map(user => 
      user.id === id ? { ...user, ...updates } : user
    ));
  };

  const deleteUser = async (id: number) => {
    await db.table('users').delete(id);
    setUsers(users.filter(user => user.id !== id));
  };

  return { users, addUser, updateUser, deleteUser };
}