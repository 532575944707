import { useState, useEffect } from 'react';
import { db } from '../db';
import { ClinicSettings } from '../types/settings';

const DEFAULT_WORKING_HOURS = {
  Monday: { start: '09:00', end: '17:00' },
  Tuesday: { start: '09:00', end: '17:00' },
  Wednesday: { start: '09:00', end: '17:00' },
  Thursday: { start: '09:00', end: '17:00' },
  Friday: { start: '09:00', end: '17:00' },
  Saturday: { start: '09:00', end: '13:00' },
  Sunday: { start: '', end: '' }
};

const DEFAULT_SETTINGS: ClinicSettings = {
  clinicName: 'Pulmonary & Sleep Lab',
  phone: '',
  email: '',
  address: '',
  workingHours: DEFAULT_WORKING_HOURS
};

export function useClinicSettings() {
  const [settings, setSettings] = useState<ClinicSettings>(DEFAULT_SETTINGS);

  useEffect(() => {
    const loadSettings = async () => {
      const savedSettings = await db.table('settings').get('clinic');
      if (savedSettings) {
        setSettings(savedSettings);
      }
    };

    loadSettings();
  }, []);

  const updateSettings = async (updates: Partial<ClinicSettings>) => {
    const newSettings = { ...settings, ...updates };
    await db.table('settings').put(newSettings, 'clinic');
    setSettings(newSettings);
  };

  return { settings, updateSettings };
}