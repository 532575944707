import React, { useState } from 'react';
import { useMedicalRecordSettings } from '../../hooks/useMedicalRecordSettings';
import { Plus, Trash2 } from 'lucide-react';

export default function MedicalRecords() {
  const { settings, updateSettings } = useMedicalRecordSettings();
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">
          Medical Records Configuration
        </h1>
        <button
          onClick={() => setIsEditing(!isEditing)}
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          {isEditing ? 'Save Changes' : 'Edit Settings'}
        </button>
      </div>

      <div className="grid grid-cols-1 gap-6">
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">
            Custom Fields
          </h2>
          <div className="space-y-4">
            {settings.customFields.map((field, index) => (
              <div key={index} className="flex items-center space-x-4">
                <input
                  type="text"
                  className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  value={field.name}
                  disabled={!isEditing}
                  placeholder="Field name"
                />
                <select
                  className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  value={field.type}
                  disabled={!isEditing}
                >
                  <option value="text">Text</option>
                  <option value="number">Number</option>
                  <option value="date">Date</option>
                  <option value="select">Select</option>
                </select>
                {isEditing && (
                  <button className="text-red-600 hover:text-red-900">
                    <Trash2 className="h-5 w-5" />
                  </button>
                )}
              </div>
            ))}
            {isEditing && (
              <button className="flex items-center space-x-2 text-blue-600 hover:text-blue-900">
                <Plus className="h-5 w-5" />
                <span>Add Field</span>
              </button>
            )}
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">
            Vital Signs Ranges
          </h2>
          <div className="space-y-4">
            {Object.entries(settings.vitalSigns).map(([key, range]) => (
              <div key={key} className="grid grid-cols-3 gap-4">
                <div className="text-sm font-medium text-gray-700">{key}</div>
                <div>
                  <input
                    type="number"
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={range.min}
                    disabled={!isEditing}
                    placeholder="Min"
                  />
                </div>
                <div>
                  <input
                    type="number"
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={range.max}
                    disabled={!isEditing}
                    placeholder="Max"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">
            Templates
          </h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Prescription Template
              </label>
              <textarea
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                rows={4}
                value={settings.templates.prescription}
                disabled={!isEditing}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Report Template
              </label>
              <textarea
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                rows={4}
                value={settings.templates.report}
                disabled={!isEditing}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}