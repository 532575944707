import React from 'react';
import { Outlet } from 'react-router-dom';
import SettingsSidebar from './SettingsSidebar';

export default function SettingsLayout() {
  return (
    <div className="flex-1 flex">
      <SettingsSidebar />
      <div className="flex-1 p-8">
        <Outlet />
      </div>
    </div>
  );
}