import React from 'react';
import { format, parseISO } from 'date-fns';
import { Clock, Calendar, User, FileText } from 'lucide-react';
import { Appointment, AppointmentStatus } from '../../types/appointment';
import { updateAppointmentStatus, deleteAppointment } from '../../db/operations/appointment.operations';

interface AppointmentListProps {
  appointments: Appointment[];
  onUpdate: () => void;
}

export default function AppointmentList({ appointments, onUpdate }: AppointmentListProps) {
  const handleStatusChange = async (id: number, status: AppointmentStatus) => {
    try {
      await updateAppointmentStatus(id, status);
      onUpdate();
    } catch (error) {
      console.error('Error updating appointment status:', error);
      alert('Failed to update appointment status');
    }
  };

  const handleDelete = async (id: number) => {
    if (!window.confirm('Are you sure you want to delete this appointment?')) {
      return;
    }

    try {
      await deleteAppointment(id);
      onUpdate();
    } catch (error) {
      console.error('Error deleting appointment:', error);
      alert('Failed to delete appointment');
    }
  };

  const getStatusColor = (status: AppointmentStatus) => {
    switch (status) {
      case 'scheduled':
        return 'bg-blue-100 text-blue-800';
      case 'confirmed':
        return 'bg-green-100 text-green-800';
      case 'completed':
        return 'bg-gray-100 text-gray-800';
      case 'cancelled':
        return 'bg-red-100 text-red-800';
      case 'no-show':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="space-y-4">
      {appointments.map(appointment => (
        <div
          key={appointment.id}
          className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-shadow"
        >
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center space-x-4">
              <User className="w-5 h-5 text-gray-400" />
              <span className="font-medium">{appointment.patient?.fullName}</span>
            </div>
            <span className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(appointment.status)}`}>
              {appointment.status}
            </span>
          </div>

          <div className="grid grid-cols-2 gap-4 mb-4">
            <div className="flex items-center space-x-2">
              <Calendar className="w-4 h-4 text-gray-400" />
              <span className="text-sm text-gray-600">
                {format(parseISO(appointment.date), 'MMM d, yyyy')}
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <Clock className="w-4 h-4 text-gray-400" />
              <span className="text-sm text-gray-600">{appointment.time}</span>
            </div>
          </div>

          {appointment.notes && (
            <div className="flex items-start space-x-2 mb-4">
              <FileText className="w-4 h-4 text-gray-400 mt-1" />
              <p className="text-sm text-gray-600">{appointment.notes}</p>
            </div>
          )}

          <div className="flex justify-between items-center">
            <select
              value={appointment.status}
              onChange={(e) => handleStatusChange(appointment.id!, e.target.value as AppointmentStatus)}
              className="text-sm border rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="scheduled">Scheduled</option>
              <option value="confirmed">Confirmed</option>
              <option value="completed">Completed</option>
              <option value="cancelled">Cancelled</option>
              <option value="no-show">No Show</option>
            </select>

            <button
              onClick={() => handleDelete(appointment.id!)}
              className="text-sm text-red-600 hover:text-red-800"
            >
              Delete
            </button>
          </div>
        </div>
      ))}

      {appointments.length === 0 && (
        <div className="text-center py-8 text-gray-500">
          No appointments found
        </div>
      )}
    </div>
  );
}