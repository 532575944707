import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import AppointmentCalendar from './AppointmentCalendar';
import AppointmentList from './AppointmentList';
import { getDayAppointments } from '../../db/operations/appointment.operations';
import { Appointment } from '../../types/appointment';

export default function AppointmentsPage() {
  const [selectedDate, setSelectedDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [loading, setLoading] = useState(true);

  const loadAppointments = async () => {
    try {
      setLoading(true);
      const dayAppointments = await getDayAppointments(selectedDate);
      setAppointments(dayAppointments);
    } catch (error) {
      console.error('Error loading appointments:', error);
      alert('Failed to load appointments');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAppointments();
  }, [selectedDate]);

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold text-gray-900 mb-8">Appointments</h1>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2">
          <AppointmentCalendar
            appointments={appointments}
            onDateSelect={setSelectedDate}
          />
        </div>

        <div>
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-lg font-semibold mb-4">
              Appointments for {format(new Date(selectedDate), 'MMMM d, yyyy')}
            </h2>
            
            {loading ? (
              <div className="flex items-center justify-center py-8">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              <AppointmentList
                appointments={appointments}
                onUpdate={loadAppointments}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}