import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Users, 
  UserPlus, 
  Search, 
  FileText, 
  Layout,
  Settings as SettingsIcon
} from 'lucide-react';

export default function Sidebar() {
  const location = useLocation();
  
  const menuItems = [
    { path: '/', icon: Layout, text: 'Dashboard' },
    { path: '/register', icon: UserPlus, text: 'New Patient' },
    { path: '/search', icon: Search, text: 'Search Patient' },
    { path: '/visit', icon: Users, text: 'Record Visit' },
    { path: '/reports', icon: FileText, text: 'Reports' },
    { path: '/settings/users', icon: SettingsIcon, text: 'Settings' }
  ];

  return (
    <div className="w-64 bg-white shadow-lg">
      <div className="p-4">
        <img 
          src="/logo.png" 
          alt="Nile Hospital" 
          className="h-16 mx-auto mb-6"
        />
      </div>
      <nav className="mt-8">
        {menuItems.map((item) => (
          <Link
            key={item.path}
            to={item.path}
            className={`flex items-center px-6 py-3 text-gray-700 hover:bg-blue-50 hover:text-blue-600 transition-colors ${
              location.pathname === item.path ? 'bg-blue-50 text-blue-600' : ''
            }`}
          >
            <item.icon className="w-5 h-5 mr-3" />
            <span className="text-sm font-medium">{item.text}</span>
          </Link>
        ))}
      </nav>
    </div>
  );
}