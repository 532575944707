import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FileText, Download, Printer } from 'lucide-react';
import { db, getPatientById, getPatientVisits } from '../db';
import { Patient, Visit } from '../types';
import { format } from 'date-fns';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const calculateAge = (dateOfBirth: string) => {
  const birthDate = new Date(dateOfBirth);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export default function Reports() {
  const { patientId } = useParams();
  const [patient, setPatient] = useState<Patient | null>(null);
  const [visits, setVisits] = useState<Visit[]>([]);
  const [selectedVisit, setSelectedVisit] = useState<Visit | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        setError(null);
        
        if (patientId) {
          const patientData = await getPatientById(Number(patientId));
          if (!patientData) {
            setError('Patient not found');
            return;
          }
          setPatient(patientData);

          const visitsData = await getPatientVisits(Number(patientId));
          setVisits(visitsData);
        } else {
          const allPatients = await db.patients.toArray();
          const allVisitsPromises = allPatients.map(async (p) => {
            const patientVisits = await getPatientVisits(p.id);
            return patientVisits.map(visit => ({
              ...visit,
              patientName: p.fullName
            }));
          });

          const allVisits = await Promise.all(allVisitsPromises);
          setVisits(allVisits.flat());
        }
      } catch (error) {
        console.error('Error loading data:', error);
        setError('Failed to load reports. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [patientId]);

  const generatePDF = async (visit: Visit) => {
    try {
      const doc = new jsPDF();
      
      // Header
      doc.setFontSize(16);
      doc.text('Respiratory Support and Sleep Lab', 105, 15, { align: 'center' });
      doc.setFontSize(14);
      doc.text('Nile Hospital Naqada', 105, 25, { align: 'center' });
      
      // Patient Info
      doc.setFontSize(12);
      const patientInfo = [
        ['Patient Name:', patient?.fullName || visit.patientName || ''],
        ['Age:', patient ? `${calculateAge(patient.dateOfBirth)} years` : ''],
        ['Phone:', patient?.phoneNumber || ''],
        ['Date:', format(new Date(visit.visitDate), 'MMM d, yyyy')],
        ['Visit Type:', visit.visitType === 'pulmonary' ? 'Pulmonary Clinic' : 'Sleep Disorders']
      ];
      
      doc.autoTable({
        startY: 35,
        head: [['Patient Information', '']],
        body: patientInfo,
        theme: 'plain',
        styles: { fontSize: 10 }
      });
      
      // Visit Details
      const visitData = [
        ['Chief Complaint', visit.chiefComplaint || 'N/A'],
        ['Symptoms', visit.symptoms || 'N/A'],
        ['Medical History', visit.medicalHistory || 'N/A'],
        ['Physical Examination', visit.physicalExamination || 'N/A'],
        ['Investigations', visit.investigations || 'N/A'],
        ['Recommendations & Treatment', visit.recommendationsAndTreatment || 'N/A']
      ];
      
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 10,
        head: [['Category', 'Details']],
        body: visitData,
        styles: { fontSize: 10 }
      });
      
      const fileName = `medical-report-${patient?.fullName || 'patient'}-${format(new Date(visit.visitDate), 'yyyy-MM-dd')}.pdf`;
      doc.save(fileName);
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Failed to generate PDF. Please try again.');
    }
  };

  const printReport = (visit: Visit) => {
    try {
      const printWindow = window.open('', '_blank');
      if (!printWindow) {
        alert('Please allow pop-ups to print reports.');
        return;
      }

      const html = `
        <html>
          <head>
            <title>Medical Report</title>
            <style>
              body { font-family: Arial, sans-serif; padding: 20px; }
              .header { text-align: center; margin-bottom: 20px; }
              .patient-info { margin-bottom: 20px; }
              .section { margin-bottom: 15px; }
              .label { font-weight: bold; }
            </style>
          </head>
          <body>
            <div class="header">
              <h1>Respiratory Support and Sleep Lab</h1>
              <h2>Nile Hospital Naqada</h2>
            </div>
            
            <div class="patient-info">
              <p><span class="label">Patient:</span> ${patient?.fullName || visit.patientName}</p>
              ${patient ? `<p><span class="label">Age:</span> ${calculateAge(patient.dateOfBirth)} years</p>` : ''}
              ${patient ? `<p><span class="label">Phone:</span> ${patient.phoneNumber}</p>` : ''}
              <p><span class="label">Date:</span> ${format(new Date(visit.visitDate), 'MMM d, yyyy')}</p>
              <p><span class="label">Visit Type:</span> ${visit.visitType === 'pulmonary' ? 'Pulmonary Clinic' : 'Sleep Disorders'}</p>
            </div>

            <div class="section">
              <p><span class="label">Chief Complaint:</span> ${visit.chiefComplaint || 'N/A'}</p>
              <p><span class="label">Symptoms:</span> ${visit.symptoms || 'N/A'}</p>
              <p><span class="label">Medical History:</span> ${visit.medicalHistory || 'N/A'}</p>
              <p><span class="label">Physical Examination:</span> ${visit.physicalExamination || 'N/A'}</p>
              <p><span class="label">Investigations:</span> ${visit.investigations || 'N/A'}</p>
              <p><span class="label">Recommendations & Treatment:</span> ${visit.recommendationsAndTreatment || 'N/A'}</p>
            </div>
          </body>
        </html>
      `;

      printWindow.document.write(html);
      printWindow.document.close();
      printWindow.print();
    } catch (error) {
      console.error('Error printing report:', error);
      alert('Failed to print report. Please try again.');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-red-600 text-center">
          <p className="text-xl font-semibold mb-2">Error</p>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h1 className="text-2xl font-bold text-gray-800 mb-8">
        {patient ? `Reports for ${patient.fullName}` : 'All Patient Reports'}
      </h1>

      <div className="bg-white rounded-lg shadow-sm p-6">
        {patient && (
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-2">Patient Information</h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-gray-600">Date of Birth</p>
                <p className="font-medium">{patient.dateOfBirth}</p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Phone Number</p>
                <p className="font-medium">{patient.phoneNumber}</p>
              </div>
            </div>
          </div>
        )}

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {!patient && (
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Patient Name
                  </th>
                )}
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Visit Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Type
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Chief Complaint
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {visits.map((visit) => (
                <tr key={visit.id} className="hover:bg-gray-50">
                  {!patient && (
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {visit.patientName}
                    </td>
                  )}
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {format(new Date(visit.visitDate), 'MMM d, yyyy')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {visit.visitType === 'pulmonary' ? 'Pulmonary Clinic' : 'Sleep Disorders'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {visit.chiefComplaint || 'N/A'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <button 
                      onClick={() => setSelectedVisit(visit)}
                      className="text-blue-600 hover:text-blue-900 mr-4"
                      title="View Details"
                    >
                      <FileText className="w-5 h-5" />
                    </button>
                    <button 
                      onClick={() => generatePDF(visit)}
                      className="text-blue-600 hover:text-blue-900 mr-4"
                      title="Download PDF"
                    >
                      <Download className="w-5 h-5" />
                    </button>
                    <button 
                      onClick={() => printReport(visit)}
                      className="text-blue-600 hover:text-blue-900"
                      title="Print Report"
                    >
                      <Printer className="w-5 h-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {visits.length === 0 && (
            <p className="text-center text-gray-500 my-4">No visits found</p>
          )}
        </div>

        {selectedVisit && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg max-w-2xl w-full p-6">
              <h2 className="text-xl font-semibold mb-4">Visit Details</h2>
              <div className="space-y-4">
                <div>
                  <p className="text-sm text-gray-600">Date</p>
                  <p className="font-medium">
                    {format(new Date(selectedVisit.visitDate), 'MMM d, yyyy')}
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-600">Chief Complaint</p>
                  <p className="font-medium">{selectedVisit.chiefComplaint || 'N/A'}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-600">Symptoms</p>
                  <p className="font-medium">{selectedVisit.symptoms || 'N/A'}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-600">Medical History</p>
                  <p className="font-medium">{selectedVisit.medicalHistory || 'N/A'}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-600">Physical Examination</p>
                  <p className="font-medium">{selectedVisit.physicalExamination || 'N/A'}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-600">Investigations</p>
                  <p className="font-medium">{selectedVisit.investigations || 'N/A'}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-600">Recommendations & Treatment</p>
                  <p className="font-medium">{selectedVisit.recommendationsAndTreatment || 'N/A'}</p>
                </div>
              </div>
              <div className="mt-6 flex justify-end">
                <button
                  onClick={() => setSelectedVisit(null)}
                  className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}