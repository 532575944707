import React, { useState, useEffect } from 'react';
import { Search } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { searchPatients } from '../db';
import { Patient } from '../types';

export default function PatientSearch() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [patients, setPatients] = useState<Patient[]>([]);

  useEffect(() => {
    const fetchPatients = async () => {
      if (searchQuery.trim()) {
        const results = await searchPatients(searchQuery);
        setPatients(results);
      } else {
        setPatients([]);
      }
    };

    fetchPatients();
  }, [searchQuery]);

  const handleVisit = (patientId: number) => {
    navigate(`/visit/${patientId}`);
  };

  const handleViewReports = (patientId: number) => {
    navigate(`/reports/${patientId}`);
  };

  return (
    <div>
      <h1 className="text-2xl font-bold text-gray-800 mb-8">Search Patients</h1>

      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="max-w-xl mx-auto mb-8">
          <div className="relative">
            <input
              type="text"
              placeholder="Search by name, phone number, or ID..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full p-4 pl-12 border rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            <Search className="absolute left-4 top-4 text-gray-400 w-5 h-5" />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date of Birth
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Phone Number
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {patients.map((patient) => (
                <tr key={patient.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {patient.fullName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {patient.dateOfBirth}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {patient.phoneNumber}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium space-x-4">
                    <button
                      onClick={() => handleVisit(patient.id)}
                      className="text-blue-600 hover:text-blue-900"
                    >
                      New Visit
                    </button>
                    <button
                      onClick={() => handleViewReports(patient.id)}
                      className="text-green-600 hover:text-green-900"
                    >
                      View Reports
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {searchQuery && patients.length === 0 && (
            <p className="text-center text-gray-500 my-4">No patients found</p>
          )}
        </div>
      </div>
    </div>
  );
}