import React from 'react';
import { NavLink } from 'react-router-dom';
import { 
  Users, 
  Building2, 
  Palette, 
  FileText, 
  Settings as SettingsIcon 
} from 'lucide-react';

const menuItems = [
  {
    path: '/settings/users',
    icon: Users,
    label: 'User Management'
  },
  {
    path: '/settings/clinic',
    icon: Building2,
    label: 'Clinic Settings'
  },
  {
    path: '/settings/customization',
    icon: Palette,
    label: 'System Customization'
  },
  {
    path: '/settings/medical-records',
    icon: FileText,
    label: 'Medical Records'
  }
];

export default function SettingsSidebar() {
  return (
    <div className="w-64 bg-white border-r border-gray-200">
      <div className="p-4 border-b border-gray-200">
        <div className="flex items-center space-x-2">
          <SettingsIcon className="w-5 h-5 text-gray-500" />
          <h2 className="text-lg font-semibold text-gray-900">Settings</h2>
        </div>
      </div>
      <nav className="p-4">
        {menuItems.map((item) => (
          <NavLink
            key={item.path}
            to={item.path}
            className={({ isActive }) =>
              `flex items-center space-x-2 px-4 py-2 rounded-md transition-colors ${
                isActive
                  ? 'bg-blue-50 text-blue-600'
                  : 'text-gray-600 hover:bg-gray-50'
              }`
            }
          >
            <item.icon className="w-5 h-5" />
            <span>{item.label}</span>
          </NavLink>
        ))}
      </nav>
    </div>
  );
}