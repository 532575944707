import React, { useState } from 'react';
import { format, startOfWeek, addDays, parseISO } from 'date-fns';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Appointment } from '../../types/appointment';
import { useNavigate } from 'react-router-dom';

interface AppointmentCalendarProps {
  appointments: Appointment[];
  onDateSelect: (date: string) => void;
}

export default function AppointmentCalendar({ appointments, onDateSelect }: AppointmentCalendarProps) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const navigate = useNavigate();

  const weeks = Array.from({ length: 5 }, (_, weekIndex) => {
    const weekStart = startOfWeek(addDays(currentDate, weekIndex * 7));
    return Array.from({ length: 7 }, (_, dayIndex) => addDays(weekStart, dayIndex));
  });

  const handlePrevMonth = () => {
    setCurrentDate(prev => addDays(prev, -30));
  };

  const handleNextMonth = () => {
    setCurrentDate(prev => addDays(prev, 30));
  };

  const getAppointmentsForDate = (date: Date) => {
    return appointments.filter(apt => 
      format(parseISO(apt.date), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
    );
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold">
          {format(currentDate, 'MMMM yyyy')}
        </h2>
        <div className="flex space-x-2">
          <button
            onClick={handlePrevMonth}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
          <button
            onClick={handleNextMonth}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-7 gap-2">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div key={day} className="text-center text-sm font-medium text-gray-500 py-2">
            {day}
          </div>
        ))}

        {weeks.flat().map((date, i) => {
          const dateAppointments = getAppointmentsForDate(date);
          const isToday = format(date, 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd');
          
          return (
            <button
              key={i}
              onClick={() => onDateSelect(format(date, 'yyyy-MM-dd'))}
              className={`
                p-2 h-24 border rounded-lg relative
                ${isToday ? 'bg-blue-50 border-blue-200' : 'hover:bg-gray-50'}
              `}
            >
              <span className={`
                text-sm ${isToday ? 'font-bold text-blue-600' : 'text-gray-700'}
              `}>
                {format(date, 'd')}
              </span>
              
              {dateAppointments.length > 0 && (
                <div className="absolute bottom-2 left-2 right-2">
                  <div className="text-xs font-medium text-blue-600 bg-blue-100 rounded px-1 py-0.5">
                    {dateAppointments.length} appointments
                  </div>
                </div>
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
}