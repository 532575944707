import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import PatientRegistration from './components/PatientRegistration';
import PatientSearch from './components/PatientSearch';
import VisitRegistration from './components/VisitRegistration';
import Reports from './components/Reports';
import AppointmentsPage from './components/appointments/AppointmentsPage';
import SettingsLayout from './components/settings/SettingsLayout';
import UserManagement from './components/settings/UserManagement';
import ClinicSettings from './components/settings/ClinicSettings';
import SystemCustomization from './components/settings/SystemCustomization';
import MedicalRecords from './components/settings/MedicalRecords';

function App() {
  return (
    <BrowserRouter>
      <div className="flex min-h-screen bg-gray-50">
        <Sidebar />
        <div className="flex-1">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/register" element={<PatientRegistration />} />
            <Route path="/search" element={<PatientSearch />} />
            <Route path="/visit/:patientId" element={<VisitRegistration />} />
            <Route path="/reports/:patientId?" element={<Reports />} />
            <Route path="/appointments" element={<AppointmentsPage />} />
            <Route path="/settings" element={<SettingsLayout />}>
              <Route path="users" element={<UserManagement />} />
              <Route path="clinic" element={<ClinicSettings />} />
              <Route path="customization" element={<SystemCustomization />} />
              <Route path="medical-records" element={<MedicalRecords />} />
            </Route>
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;