import { db } from '../index';
import { Patient } from '../../types';

export const createPatient = async (patientData: Omit<Patient, 'id' | 'createdAt'>): Promise<number> => {
  const timestamp = new Date().toISOString();
  const patient: Omit<Patient, 'id'> = {
    ...patientData,
    createdAt: timestamp
  };
  
  return await db.patients.add(patient);
};

export const getPatientById = async (id: number): Promise<Patient | undefined> => {
  return await db.patients.get(id);
};

export const getRecentPatients = async (limit: number = 5): Promise<Patient[]> => {
  return await db.patients
    .orderBy('createdAt')
    .reverse()
    .limit(limit)
    .toArray();
};

export const getAllPatients = async (): Promise<Patient[]> => {
  return await db.patients.toArray();
};

export const searchPatients = async (query: string): Promise<Patient[]> => {
  const searchTerm = query.trim().toLowerCase();
  if (!searchTerm) return [];

  return await db.patients
    .filter(patient => 
      patient.fullName.toLowerCase().includes(searchTerm) ||
      patient.phoneNumber.includes(searchTerm)
    )
    .toArray();
};

export const updatePatient = async (id: number, data: Partial<Patient>): Promise<number> => {
  return await db.patients.update(id, data);
};

export const deletePatient = async (id: number): Promise<void> => {
  await db.patients.delete(id);
};