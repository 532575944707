import React from 'react';
import { Users, UserPlus, Clock, Calendar, Stethoscope, Moon } from 'lucide-react';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../db';
import { StatCard } from './dashboard/StatCard';
import { RecentPatients } from './dashboard/RecentPatients';
import { Header } from './dashboard/Header';
import { Footer } from './dashboard/Footer';
import { getTodayStart } from '../utils/dates';

export default function Dashboard() {
  const patients = useLiveQuery(() => db.patients.toArray());
  const visits = useLiveQuery(() => db.visits.toArray());

  const todayStart = getTodayStart();

  const todayPatients = (patients || []).filter(
    patient => patient.createdAt && new Date(patient.createdAt) >= todayStart
  );

  const todayVisits = (visits || []).filter(
    visit => visit.visitDate && new Date(visit.visitDate) >= todayStart
  );

  const pulmonaryCases = (visits || []).filter(visit => visit.visitType === 'pulmonary').length;
  const sleepCases = (visits || []).filter(visit => visit.visitType === 'sleep').length;

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <StatCard
          icon={Users}
          title="Total Patients"
          value={patients?.length || 0}
          color="bg-blue-500"
        />
        <StatCard
          icon={UserPlus}
          title="New Patients (Today)"
          value={todayPatients.length}
          color="bg-green-500"
        />
        <StatCard
          icon={Clock}
          title="Today's Visits"
          value={todayVisits.length}
          color="bg-yellow-500"
        />
        <StatCard
          icon={Calendar}
          title="Pending Appointments"
          value={todayVisits.length}
          color="bg-purple-500"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <StatCard
          icon={Stethoscope}
          title="Total Pulmonary Cases"
          value={pulmonaryCases}
          color="bg-indigo-500"
        />
        <StatCard
          icon={Moon}
          title="Total Sleep Lab Cases"
          value={sleepCases}
          color="bg-teal-500"
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 flex-grow">
        <RecentPatients />
      </div>

      <Footer />
    </div>
  );
}